import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';
import { routes } from './app.routes';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideAuth0({
            domain: environment.LOGIN_URL,
            clientId: environment.CLIENT_ID,
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: 'https://bannerflow.com/resources/',
                scope: 'openid profile email offline_access'
            },
            httpInterceptor: {
                allowedList: environment.API_URLS.map(url => `${url}/*`)
            },
            useRefreshTokens: true
        })
    ]
};
